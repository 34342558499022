<!-- 公告 -->
<template>
    <div class="text-left" style="background:#fff">
        <div :style="{width: this.$oucy.pageWidth+'px'}" class="boxs">
            <div class="box">
                <div class="title">{{obj.adTitle ||obj.noticeTitle}}</div>
                <div class="date"><span class="unit" v-if="obj.adUnit">招标单位：{{obj.adUnit}}</span> {{obj.adUpdatetime || obj.noticeUpdatetime}}</div>
                
               <div v-html="obj.adContent||obj.noticeContent"></div>
            </div>
        </div>
    </div>
</template>
<script>
import oucy from "../../../util/oucyUtil";
import { bidding,notice } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Index",
    data() {
        return {
            id: null,
            type:1,
            obj:{}
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.type = this.$route.query.type
        if (this.type==1) {
            this.getNoticeById()
        } else {
            this.getTenderById()
        }
    },
    methods: {
        getTenderById() {
            bidding.getTenderById({ id: this.id }).then(res => {
                this.obj=res
                this.$oucy.setTitle(this.obj.adTitle||this.obj.noticeTitle)
            })
        },
        getNoticeById() {
            notice.getNoticeById({ id: this.id }).then(res => {
                this.obj=res
                this.$oucy.setTitle(this.obj.adTitle||this.obj.noticeTitle)
            })
        }
    },
}
</script>
<style scoped lang="less">
.boxs {
    margin: 0 auto;
    padding-bottom: 50px;

    .box {
        background: #fff;
    }
}

.title {
    padding: 69px 0 10px 0;
    text-align: center;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;

}
.unit{

}
.date {
    text-align: center;
    margin-bottom: 10px;

    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #666666;
}
</style>